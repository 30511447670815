import firebase from 'firebase';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
// import { defaultLanguage } from '../../prismic-configuration';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import qrCodePlaceholder from '../images/qrcode_enterprof.org.png';
import { linkResolver } from '../utils/LinkResolver';

const isBrowser = typeof window !== 'undefined';

function FullPortfolio({ location, data }) {
  // const [status, setStatus] = useState('pending');
  // const [message, setMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  // const [formFieldEntries, setFormFieldEntries] = useState([]);

  // get user id from params
  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.userId) {
      setUserId(params.userId);
    } else {
      // setStatus('failed');
      // setMessage('Missing needed information..');
    }
  }, [location.search]);

  // get all users
  useLayoutEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firebase
          .firestore()
          // Access the "users" collection
          .collection('usersCollection')

          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            setAllUsers(listUsers);
          });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // get form field entries from user personal profile
  useEffect(() => {
    if (allUsers.length !== 0) {
      const currentUserData = allUsers.filter(userFromList => userFromList.id === userId)[0];
      setCurrentUser(currentUserData);
    }
  }, [allUsers]);

  if (!data) return null;

  const pageContent = data.prismicProfessionalPortfolioCard;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  // const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const topMenu = data.prismicMenuLink || {};

  const userName = currentUser?.full_name;
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.title.text} lang={lang} />
      <Container className="remember_wrapper">
        <div className="collection-page-title">
          <h1 className="page-title">{page.title.text}</h1>
        </div>
        <hr />
        <Row className="mt-2 mb-5">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="d-flex justify-content-between bg-secondary bg-gradient text-white p-2 shadow">
                  {userName || 'N/A'}
                  <h5>
                    {page?.card_labels[0]?.registration_number_label}
                    <span>: {currentUser?.data?.code?.text || 'N/A'}</span>
                  </h5>
                </Card.Title>
                <hr />
                <div className="card-text">
                  <ul className="card-list_wrapper">
                    <li className="card-list-item fw-bolder">
                      {page?.card_labels[0]?.registration_date_label}:
                      <span> {currentUser?.data?.reg_date || 'N/A'}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {page?.card_labels[0]?.valid_until_date_label}:
                      <span> {currentUser?.data?.valid_until_date || 'N/A'}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {page?.card_labels[0]?.registration_level_label}:
                      <span> {currentUser?.data?.reg_level || 'N/A'}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {page?.card_labels[0]?.area_of_expertise_label}:
                      <span> {currentUser?.data?.area?.text || 'N/A'}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {page?.card_labels[0]?.accredited_pdp_completed_label}:
                      <a
                        className="text-primary"
                        href={currentUser?.data?.a_pdp_completed_link}
                        target={currentUser?.data?.a_pdp_completed_link}
                      >
                        {' '}
                        link
                      </a>
                    </li>
                    --------------------------------
                    {currentUser?.isEmailVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.card_labels[0]?.email_label}
                        <span>
                          {' '}
                          {' :  '}
                          {currentUser?.data?.email || 'N/A'}
                        </span>
                      </li>
                    )}
                    {currentUser?.isUniversityVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.card_labels[0]?.university_label}
                        <span>
                          {' :  '}
                          {currentUser?.data?.university || 'N/A'}
                        </span>
                      </li>
                    )}
                    <li className="card-list-item fw-bolder">
                      {page?.card_labels[0]?.country_label}
                      <span>
                        {'  : '}
                        {currentUser?.data?.country || 'N/A'}
                      </span>
                    </li>
                    {/* facebook_link */}
                    {currentUser?.isFacebookLinkVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.body[0]?.items[10].title}
                        {currentUser?.facebook_link && (
                          <a
                            className="text-primary"
                            href={currentUser?.facebook_link}
                            target={currentUser?.facebook_link}
                          >
                            {' '}
                            link
                          </a>
                        )}
                      </li>
                    )}
                    {/* linkedin_link */}
                    {currentUser?.isLinkedinLinkVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.body[0]?.items[11].title}

                        {currentUser?.linkedin_link && (
                          <a
                            className="text-primary"
                            href={currentUser?.linkedin_link}
                            target={currentUser?.linkedin_link}
                          >
                            {' '}
                            link
                          </a>
                        )}
                      </li>
                    )}
                    {/* instagram_link */}
                    {currentUser?.isInstagramLinkVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.body[0]?.items[12].title}
                        {currentUser?.instagram_link && (
                          <a
                            className="text-primary"
                            href={currentUser?.instagram_link}
                            target={currentUser?.instagram_link}
                          >
                            {' '}
                            link
                          </a>
                        )}
                      </li>
                    )}
                    {/* twitter_link */}
                    {currentUser?.isTwitterLinkVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.body[0]?.items[13].title}
                        {currentUser?.twitter_link && (
                          <a
                            className="text-primary"
                            href={currentUser?.twitter_link}
                            target={currentUser?.twitter_link}
                          >
                            {' '}
                            link
                          </a>
                        )}
                      </li>
                    )}
                    {/* personal_website_link */}
                    {currentUser?.isPersonalWebsiteVisible && (
                      <li className="card-list-item fw-bolder">
                        {page?.body[0]?.items[14].title}

                        {currentUser?.personal_website_link && (
                          <a
                            className="text-primary"
                            href={currentUser?.personal_website_link}
                            target={currentUser?.personal_website_link}
                          >
                            {' '}
                            link
                          </a>
                        )}
                      </li>
                    )}
                  </ul>
                  <span className="card-qr-code_wrapper">
                    <img
                      className="qr-code"
                      src={
                        currentUser?.data?.qr_code === 'N/A' ||
                        currentUser?.data?.qr_code === 'not provided'
                          ? qrCodePlaceholder
                          : currentUser?.data?.qr_code
                      }
                      alt="qr-code"
                      width="100px"
                    />
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query prismicProfessionalPortfolioCardQuery($id: String, $lang: String) {
    prismicProfessionalPortfolioCard(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        card_labels {
          accredited_pdp_completed_label
          area_of_expertise_label
          country_label
          email_label
          registration_date_label
          registration_level_label
          registration_number_label
          university_label
          valid_until_date_label
        }
        title {
          text
        }
        body {
          ... on PrismicProfessionalPortfolioCardDataBodyForm {
            id
            items {
              description
              field_type
              form_type
              name
              placeholder
              radio_options
              required
              title
            }
          }
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(FullPortfolio, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
